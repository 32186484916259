<template>
    <div>
        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
            <b-card
                bg-variant="bluenavy"
                text-variant="white"
                header-text-variant="white"
                header-bg-variant="bluenavy"
                header-tag="h2"
                :header="comitato.displayname"
            >
                <template #header>
                    <div style="margin: -2rem -2.25rem; padding: 2rem 2.25rem;">
                        <div class="row">
                            <div class="col-md-8">
                                <h2>{{comitato.displayname}}</h2>
                            </div>
                            <div class="col-md-4">
                                <h2 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.totalcredit > 0">
                                    <span class="text-success">Credito: {{totalcredit}} €</span>
                                </h2>

                                <h2 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.totalcredit === 0">
                                    <span class="text-danger">Credito: {{totalcredit}} €</span>
                                </h2>

                                <h2 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.past_debt >= 0">
                                    <span class="text-success">Debito pregresso: {{past_debt}} €</span>
                                </h2>

                                <h2 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.past_debt < 0">
                                    <span class="text-danger">Debito pregresso: {{past_debt}} €</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </template>
                <b-card-text>
                    <form class="form">
                        <div class="card-body pb-0">
                            <div class="form-group row">
                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Id:</label>
                                    <div v-if="loaded">COD. COM.: {{comitato.id}}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Codice Fiscale:</label>
                                    <div v-if="loaded">{{comitato.taxcode}}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Partita IVA:</label>
                                    <div v-if="loaded">{{comitato.vatnumber}}</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Telefono:</label>
                                    <div v-if="loaded">{{comitato.phonenumber}}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Cellulare:</label>
                                    <div v-if="loaded">{{comitato.cellphonenumber}}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Fax:</label>
                                    <div v-if="loaded">{{comitato.faxphonenumber}}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">E-mail:</label>
                                    <div v-if="loaded && comitato.email"><a class="text-success" :href="'mailto:' + comitato.email">{{comitato.email}}</a></div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label class="text-white font-weight-bolder font-size-h3">Indirizzo:</label>
                                    <div v-if="typeof comitato.address !== 'undefined'">
                                        {{comitato.address.streetaddress}}
                                        -
                                        {{comitato.address.zipcode}}
                                        -
                                        {{comitato.address.cityname}}
                                        -
                                        ({{comitato.address.provstates.provstatecode}})
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="text-white font-weight-bolder font-size-h3">Spedizione Materiale Presso:</label>
                                    <div>{{comitato.shippinginfo}}</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-card-text>
            </b-card>

            <div class="mt-3 mb-3 pt-3 pb-3">&nbsp;</div>

            <b-card no-body header-bg-variant="bluenavy" header="Gestione Comitato" header-text-variant="white" header-tag="h2" border-variant="bluenavy">
                <b-tabs card justified v-model="tabIndex">
                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Organigramma" :title-link-class="linkClass(0)" active>
                        <b-card-text>
                            <h6>Struttura Comitato</h6>
                            Se un componente del Consiglio Direttivo ricopre cariche di Istruttore, Maestro, Allenatore,Tecnico, Arbitro o Medico; è obbligatorio ripetere il nominativo all'interno dell'organigramma.
                        </b-card-text>

                        <SmartTable
                            ref="consiglio"
                            url="/committee/board/index"
                            base_url="/committee/board"
                            base_path="/committee/board"
                            :is_dialog="true"
                            :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }, { name: 'isdirector', filter: '1' }]"
                            custom_class="mx-0 px-0"
                            @close-modal="closeModalConsiglio"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Consiglio Direttivo</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable
                            ref="organigramma"
                            url="/committee/board/index"
                            base_url="/committee/board"
                            base_path="/"
                            is_dialog="true"
                            :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }, { name: 'isdirector', filter: '0' }]"
                            custom_class="mx-0 px-0"
                            @close-modal="closeModalOrganigramma"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Organigramma</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="storicoorganigramma" url="/committee/boardhistory/index" :is_dialog="true" base_url="/committee/boardhistory" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Storico</span></h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Credito" :title-link-class="linkClass(1)">
                        <b-card-text>
                            <div class="row">
                                <div class="col-md-6">
                                    <h3>Situazione Credito</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.totalcredit > 0">
                                        <span class="text-success">Credito: {{totalcredit}} €</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.totalcredit === 0">
                                        <span class="text-danger">Credito: {{totalcredit}} €</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.past_debt >= 0">
                                        <span class="text-success">Debito pregresso: {{past_debt}} €</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.past_debt < 0">
                                        <span class="text-danger">Debito pregresso: {{past_debt}} €</span>
                                    </h6>

                                    <h6 class="mt-5">Eventuali Annotazioni:</h6>
                                    <p v-if="typeof comitato.credit !== 'undefined'">{{comitato.credit.note}}</p>

                                    <h6 class="mt-5">Annotazioni Debito Pregresso:</h6>
                                    <p v-if="typeof comitato.credit !== 'undefined'">{{comitato.credit.note_past_debt_committee}}</p>
                                </div>

                                <div class="col-md-6">
                                    <CommitteeChargeCredit :idcommittee="$route.params.id" v-on:credit-recharged="CreditRecharged"></CommitteeChargeCredit><br>
                                    <a v-if="false" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"><i class="fas fa-plus"></i> Aumenta Credito Comitato </a><br>
                                    <a v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="aggiungiOperazioneComitato"><i class="fas fa-plus"></i> Aggiungi Operazione Credito Comitato </a>
                                    <a v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'" href="#" class="mt-2 btn btn-info font-weight-bolder mr-3" @click.prevent="modificaCreditoComitato"><i class="fas fa-edit"></i> Modifica Credito Comitato </a>

                                    <div v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">
                                        <h6 class="mt-5">Annotazioni Debito Pregresso per la Segreteria Nazionale:</h6>
                                        <p v-if="typeof comitato.credit !== 'undefined'">{{comitato.credit.note_past_debt_internal}}</p>
                                    </div>

                                    <b-modal ref="aggiungiOperazioneComitatoModal" size="lg" hide-footer title="Aggiungi Operazione Credito Comitato">
                                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                            <form>
                                                <div class="form-group">
                                                    <label>Importo:</label>
                                                    <input v-model="operationform.operationammount" type="text" class="form-control" @change="checkoperationammount">
                                                </div>

                                                <div class="form-group">
                                                    <label>Tipo operazione:</label>
                                                    <select v-model="operationform.idoperationtype" class="form-control">
                                                        <option value="3005">Addebito Costo Ricarica</option>
                                                        <option value="3005">Addebito Costo Ricarica</option>
                                                        <option value="3002">Addebito Generazione Certificato</option>
                                                        <option value="3001">Addebito Richiesta Tessere</option>
                                                        <option value="3003">Addebito Upgrade Assicurativo</option>
                                                        <option value="3007">Affiliazione Sodalizio</option>
                                                        <option value="3004">Altro Addebito</option>
                                                        <option value="3006">Ricarica Credito</option>
                                                        <option value="3008">Storno</option>
                                                        <option value="3009">Storno Assicurativo</option>
                                                    </select>
                                                </div>

                                                <div class="form-group">
                                                    <label>Descrizione:</label>
                                                    <input type="text" v-model="operationform.description" class="form-control" maxlength="250">
                                                </div>

                                                <div class="form-group">
                                                    <label>Tipo pagamento:</label>
                                                    <select v-model="operationform.idpaymenttype" class="form-control">
                                                        <option value="3103">Bonifico</option>
                                                        <option value="3102">Carta ACSI</option>
                                                        <option value="3101">Carta di Credito</option>
                                                        <option value="3104">Credito ACSI</option>
                                                        <option value="3105">MyBank</option>
                                                        <option value="3106">PayPal</option>
                                                        <option value="3108">Ccp (conto corrente postale)</option>
                                                        <option value="3109">Bollettino</option>
                                                    </select>
                                                </div>

                                                <div class="form-group">
                                                    <label>Note:</label>
                                                    <textarea v-model="operationform.note" rows="3" class="form-control"></textarea>
                                                </div>
                                            </form>

                                            <div class="d-flex justify-content-end">
                                                <b-button class="mt-3" variant="danger" @click.prevent="hideModalAggiungiOperazioneComitato">Annulla</b-button>
                                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaAggiungiOperazioneComitato">Aggiungi Operazione</b-button>
                                            </div>
                                        </b-overlay>
                                    </b-modal>

                                    <b-modal ref="modificaCreditoComitatoModal" size="lg" hide-footer title="Modifica Credito Comitato">
                                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                            <form>
                                                <div class="form-group">
                                                    <label>Credito:</label>
                                                    <input v-model="creditform.totalcredit" type="text" class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Debito Pregresso:</label>
                                                    <input v-model="creditform.past_debt" type="text" class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Note:</label>
                                                    <textarea v-model="creditform.note" rows="3" class="form-control"></textarea>
                                                </div>

                                                <div class="form-group">
                                                    <label>Note Debito Pregresso per il Comitato:</label>
                                                    <textarea v-model="creditform.note_past_debt_committee" rows="3" class="form-control"></textarea>
                                                </div>

                                                <div class="form-group">
                                                    <label>Note Debito Pregresso per la Segreteria Nazionale:</label>
                                                    <textarea v-model="creditform.note_past_debt_internal" rows="3" class="form-control"></textarea>
                                                </div>
                                            </form>

                                            <div class="d-flex justify-content-end">
                                                <b-button class="mt-3" variant="danger" @click.prevent="hideModalModificaCreditoComitato">Annulla</b-button>
                                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaModificaCreditoComitato">Modifica</b-button>
                                            </div>
                                        </b-overlay>
                                    </b-modal>
                                </div>
                            </div>
                        </b-card-text>

                        <SmartTable
                            ref="creditoperations"
                            url="/committee/credit-operation/index"
                            base_url="/committee/credit-operation"
                            base_path="/committee/credit-operation"
                            :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]"
                            :is_dialog="true"
                            custom_class="mx-0 px-0"
                            v-on:close-modal="closeModal"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Storico Operazioni</span></h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Tessere" :title-link-class="linkClass(2)">
                        <b-card-text>
                            <div class="row">
                                <div class="col-md-3">
                                    <h3>Gestione Tessere Comitato</h3>
                                    <h6>
                                        Disponibilità Tessere {{current_year}}
                                    </h6>
                                </div>

                                <div class="col-md-3">
                                    <form>
                                        <div class="form-group">
                                            <label for="idannuality">Annualità:</label>
                                            <select id="idannuality" v-model="idannuality" class="form-control">
                                                <option value="">Seleziona un'annualità</option>
                                                <option v-for="annuality in annualities" :value="annuality.id">{{annuality.label}}</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-md-6 text-center">
                                    <a v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="mostraModaleTessereComitato"><i class="fas fa-plus"></i> Assegna Tessere Comitato </a>
                                </div>
                            </div>
                        </b-card-text>

                        <b-modal ref="assegnaTessereComitatoModal" size="lg" hide-footer title="Assegna Tessere ad un Comitato">
                            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                <p v-if="!automaticassigncardflag">Selezionare il range delle tessere da assegnare al Comitato</p>
                                <p v-if="automaticassigncardflag">Selezionare il numero e la tipologia di tessere da assegnare al Comitato</p>

                                <form>
                                    <div class="form-group">
                                        <label>Seleziona la Tipologia:</label>
                                        <select v-model="idmembercardtype" class="form-control">
                                            <option value="2305">Ciclismo</option>
                                            <option value="2301">Circolo</option>
                                            <option value="2306">Dirigenti</option>
                                            <option value="2303">Equitazione</option>
                                            <option value="2304">Motori</option>
                                            <option value="2308">Motori raduno</option>
                                            <option value="2310">Prima Sport</option>
                                            <option value="2309">SCA</option>
                                            <option value="2302">Sport</option>
                                            <option value="2307">Tecnico</option>
                                            <option value="2311">Volontario</option>
                                        </select>
                                    </div>

                                    <div v-if="!automaticassigncardflag" class="row">
                                        <div class="col-md-6">
                                            <label>Range Iniziale</label>
                                            <input v-model="range_iniziale_comitato" type="number" class="form-control" @change="checkrangeComitato">
                                        </div>

                                        <div class="col-md-6">
                                            <label>Range Finale</label>
                                            <input v-model="range_finale_comitato" type="number" class="form-control" @change="checkrangeComitato">
                                        </div>
                                    </div>

                                    <div v-if="automaticassigncardflag" class="form-group">
                                        <label>Inserire il numero di tessere:</label>
                                        <input type="number" v-model="numero_tessere" class="form-control" @change="checkrangeComitato">
                                    </div>
                                </form>

                                <div class="d-flex justify-content-end">
                                    <b-button class="mt-3" variant="danger" @click.prevent="hideModalAssegnaTessereComitato">Annulla</b-button>
                                    <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaAssegnaTessereComitato">Assegna</b-button>
                                </div>
                            </b-overlay>
                        </b-modal>

                        <SmartTable
                            ref="tesseredisponibili"
                            url="/committee/membercard-range/index"
                            base_path="/"
                            :fixed_filters="(
                                idannuality && membercard_range
                                ? [{ name: 'idcommittee', filter: $route.params.id }, { name: 'idannuality', filter: idannuality }, { name: 'membercard_range', filter: membercard_range }]
                                :
                                (
                                    idannuality
                                    ? [{ name: 'idcommittee', filter: $route.params.id }, { name: 'idannuality', filter: idannuality }]
                                    :
                                    (
                                        membercard_range
                                        ? [{ name: 'idcommittee', filter: $route.params.id }, { name: 'membercard_range', filter: membercard_range }]
                                        : [{ name: 'idcommittee', filter: $route.params.id }]
                                    )
                                )
                            )"
                            custom_class="mx-0 px-0"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Tessere Disponibili</span></h2>
                            </template>

                            <template v-slot:before-simple-search>
                                <div class="form-group mt-3">
                                    <b-form-group
                                        id="membercard-range-group"
                                        label="Cerca intervallo tessere:"
                                        label-for="membercard_range"
                                    >
                                        <div class="input-group">
                                            <b-form-input
                                                id="membercard_range"
                                                v-model="membercard_range"
                                                type="number"
                                                placeholder="Cerca intervallo tessere..."
                                                class="form-control form-control-solid"
                                                step="1"
                                                debounce="500"
                                            ></b-form-input>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" @click="clearMembercardRange" type="button">Reimposta</button>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-right">
                                    <a v-if="slotProps.values.item.leftcard > 0" href="#" @click.prevent="assignCards(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Assegna" alt="Assegna">
                                        <i class="fas fa-check"></i>
                                    </a>

                                    <a v-if="slotProps.values.item.assignedcard === 0 && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" @click.prevent="deleteCardsCommittee(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Elimina" alt="Elimina">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>

                                    <a href="#" @click.prevent="viewCards(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-success" title="Visualizza Tessere Assegnate" alt="Visualizza Tessere Assegnate">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>

                        <SmartTable
                            ref="tessereAssegnateTable"
                            url="/committee/assigned-membercard-range/index"
                            base_path="/"
                            :fixed_filters="[{ name: 'idcommitteemembercardrange', filter: idcommitteemembercardrange }]"
                            custom_class="mx-0 px-0"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Tessere Assegnate</span></h2>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-right">
                                    <a v-if="slotProps.values.item.assignedcard === 0 && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" @click.prevent="deleteCards(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Elimina" alt="Elimina">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>

                        <b-modal ref="assegnaTessereModal" size="lg" hide-footer title="Assegna Tessere ad un Sodalizio">
                            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                <p>Selezionare il range delle tessere da assegnare al Sodalizio</p>

                                <form>
                                    <div class="form-group">
                                        <label>Sodalizi affiliati ed attivi:</label>
                                        <multiselect style="width: 100%;"
                                                     v-model="idsportingclub"
                                                     :options="sportingclubs"
                                                     :multiple="false"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     placeholder="Seleziona un sodalizio"
                                                     label="displayname"
                                                     track-by="id"
                                                     :allow-empty="false"
                                                     selectLabel=""
                                                     selectedLabel=""
                                                     deselectLabel=""
                                                     showLabels=""
                                        >
                                            <template v-slot:noOptions>
                                                Nessuna opzione disponibile
                                            </template>
                                        </multiselect>
                                    </div>

                                    <div v-if="!automaticassigncardflag" class="form-group">
                                        <label>Seleziona il range di tessere:</label>
                                        <multiselect style="width: 100%;"
                                                     v-model="range"
                                                     :options="ranges"
                                                     :multiple="false"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     placeholder="Seleziona un range di tessere"
                                                     label="rangename"
                                                     track-by="id"
                                                     :allow-empty="false"
                                                     selectLabel=""
                                                     selectedLabel=""
                                                     deselectLabel=""
                                                     showLabels=""
                                        >
                                            <template v-slot:noOptions>
                                                Nessuna opzione disponibile
                                            </template>
                                        </multiselect>
                                    </div>

                                    <div v-if="!automaticassigncardflag" class="form-group">
                                        <v-app>
                                            <v-form>
                                                <v-container>
                                                    <v-range-slider
                                                        v-model="range.value"
                                                        :max="range.max"
                                                        :min="range.min"
                                                        hide-details
                                                        class="align-center"
                                                    >
                                                    </v-range-slider>

                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label>Range Iniziale</label>
                                                            <input v-model="range.value[0]" type="number" class="form-control" @change="checkrange">
                                                        </div>

                                                        <div class="col-md-6">
                                                            <label>Range Finale</label>
                                                            <input v-model="range.value[1]" type="number" class="form-control" @change="checkrange">
                                                        </div>
                                                    </div>
                                                </v-container>
                                            </v-form>
                                        </v-app>

                                        <strong>Quantità tessere scelte:</strong> {{quantita_tessere_scelte}}
                                    </div>

                                    <div v-if="automaticassigncardflag" class="form-group">
                                        <label>Inserire il numero di tessere:</label>
                                        <input type="number" v-model="numero_tessere_sodalizio" class="form-control" @change="checkrangeSodalizio">
                                    </div>
                                </form>

                                <div class="d-flex justify-content-end">
                                    <b-button class="mt-3" variant="danger" @click.prevent="hideModalAssegnaTessere">Annulla</b-button>
                                    <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaAssegnaTessere">Assegna</b-button>
                                </div>
                            </b-overlay>
                        </b-modal>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Certificati" :title-link-class="linkClass(3)">
                        <b-card-text>
                            <div class="row">
                                <div class="col-md-6">
                                    <h3>Gestione Certificati</h3>
                                </div>

                                <div class="col-md-6">
                                </div>
                            </div>
                        </b-card-text>

                        <SmartTable
                            url="/committee/sportingclub-certificate/index"
                            base_path="/"
                            :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]"
                            custom_class="mx-0 px-0"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Certificati dei Sodalizi</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable
                            url="/committee/committee-certificate/index"
                            base_path="/"
                            :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]"
                            custom_class="mx-0 px-0"
                        >
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Certificati del Comitato</span></h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'" class="border border-primary border-top-0 rounded-bottom" title="Listino Nazionale" :title-link-class="linkClass(4)">
                        <b-card-text>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3>ABILITAZIONE COSTO AFFILIAZIONE PERSONALIZZATO</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.enablecustomaffiliationpriceflag && parseInt(comitato.credit.enablecustomaffiliationpriceflag)">
                                        <span class="text-success">PREZZO PERSONALIZZATO ABILITATO</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && (!comitato.credit.enablecustomaffiliationpriceflag || !parseInt(comitato.credit.enablecustomaffiliationpriceflag))">
                                        <span class="text-danger">PREZZO PERSONALIZZATO DISABILITATO</span>
                                    </h6>
                                </div>

                                <div class="col-md-4">
                                    <h3>PREZZO AFFILIAZIONE PERSONALIZZATO</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.enablecustomaffiliationpriceflag && parseInt(comitato.credit.enablecustomaffiliationpriceflag)">
                                        <span class="text-success">{{ formatEuro(comitato.credit.affiliationprice) }} €</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && (!comitato.credit.enablecustomaffiliationpriceflag || !parseInt(comitato.credit.enablecustomaffiliationpriceflag))">
                                        <span class="text-danger">{{ formatEuro(5) }} €</span>
                                    </h6>
                                </div>

                                <div class="col-md-4">
                                    <a href="#" class="mt-2 btn btn-info font-weight-bolder mr-3" @click.prevent="modificaPrezzoAffiliazione"><i class="fas fa-edit"></i> Modifica Prezzo Affiliazione</a>
                                </div>
                            </div>
                        </b-card-text>

                        <b-modal ref="modificaPrezzoAffiliazioneModal" size="lg" hide-footer title="Modifica Prezzo Affiliazione">
                            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                <form>
                                    <b-form-group label="Prezzo Affiliazione Personalizzato Abilitato: *" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="prezzoaffiliazioneform.enablecustomaffiliationpriceflag"
                                            :aria-describedby="ariaDescribedby"
                                        >
                                            <b-form-radio value="1">Si</b-form-radio>
                                            <b-form-radio value="0">No</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>

                                    <div class="form-group">
                                        <label for="prezzo_affiliazione">Prezzo Affiliazione Personalizzato:</label>
                                        <currency-input class="border-primary form-control form-control-solid" placeholder="Prezzo Affiliazione Personalizzato" v-model="prezzoaffiliazioneform.affiliationprice" style="width: 300px;" maxlength="15" :options="{ currency: 'EUR', hideCurrencySymbolOnFocus: true, hideGroupingSeparatorOnFocus: true, hideNegligibleDecimalDigitsOnFocus: true }" />
                                    </div>
                                </form>

                                <div class="d-flex justify-content-end">
                                    <b-button class="mt-3" variant="danger" @click.prevent="hideModificaPrezzoAffiliazioneModal">Annulla</b-button>
                                    <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaModificaPrezzoAffiliazioneModal">Conferma</b-button>
                                </div>
                            </b-overlay>
                        </b-modal>

                        <SmartTable ref="prezzitessere" url="/committee/membercard-price/index" :is_dialog="true"
                                    base_url="/committee/membercard-price" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziTessere">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Tessere</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezziassicurazioni" url="/committee/insurance-price/index" :is_dialog="true"
                                    base_url="/committee/insurance-price" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziAssicurazioni">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Assicurazioni</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezzicertificati" url="/committee/certificate-price/index" :is_dialog="true"
                                    base_url="/committee/certificate-price" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziCertificati">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Certificati</span></h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab v-if="$store.getters.currentUser.usertype === 'Comitato' || $store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin'" class="border border-primary border-top-0 rounded-bottom" title="Listino Comitato" :title-link-class="linkClass(5)">

                        <b-card-text>
                            <div class="row">
                                <div class="col-md-4">
                                    <h3>Abilitazione Richieste Sodalizio</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.enablesportingclubrequestesflag">
                                        <span class="text-success">RICHIESTE ABILITATE</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && !comitato.credit.enablesportingclubrequestesflag">
                                        <span class="text-danger">RICHIESTE DISABILITATE</span>
                                    </h6>
                                </div>

                                <div class="col-md-4">
                                    <h3>Tipo di listino da utilizzare</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.sportingclubpricetype">
                                        <span class="text-success" v-if="comitato.credit.sportingclubpricetype === 'default'">Default</span>
                                        <span class="text-success" v-if="comitato.credit.sportingclubpricetype === 'custom'">Personalizzato</span>
                                    </h6>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && !comitato.credit.sportingclubpricetype">
                                        <span class="text-danger">NON SPECIFICATO</span>
                                    </h6>
                                </div>

                                <div class="col-md-4">
                                    <h3>Informazioni di pagamento</h3>

                                    <h6 v-if="typeof comitato.credit !== 'undefined' && comitato.credit.paymentrequestesinfo">
                                        <pre>{{comitato.credit.paymentrequestesinfo}}</pre>
                                    </h6>

                                    <a v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'|| $store.getters.currentUser.usertype === 'Comitato'" href="#" class="mt-2 btn btn-info font-weight-bolder mr-3" @click.prevent="modificaInfoRichieste"><i class="fas fa-edit"></i> Modifica Stato Richieste</a>
                                </div>
                            </div>
                        </b-card-text>

                        <b-modal ref="modificaInfoRichiesteModal" size="lg" hide-footer title="Modifica Informazioni Richieste">
                            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                <form>
                                    <b-form-group label="Richieste Abilitate: *" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="richiesteform.enablesportingclubrequestesflag"
                                            :aria-describedby="ariaDescribedby"
                                        >
                                            <b-form-radio value="1">Si</b-form-radio>
                                            <b-form-radio value="0">No</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>

                                    <div class="form-group">
                                        <label>Tipo di listino da utilizzare:</label>
                                        <select v-model="richiesteform.sportingclubpricetype" class="form-control">
                                            <option value="">Seleziona un tipo di listino</option>
                                            <option value="default">Default</option>
                                            <option value="custom">Personalizzato</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label>Informazioni di pagamento:</label>
                                        <textarea v-model="richiesteform.paymentrequestesinfo" rows="3" class="form-control"></textarea>
                                    </div>
                                </form>

                                <div class="d-flex justify-content-end">
                                    <b-button class="mt-3" variant="danger" @click.prevent="hideModificaInfoRichiesteModal">Annulla</b-button>
                                    <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaModificaInfoRichiesteModal">Conferma</b-button>
                                </div>
                            </b-overlay>
                        </b-modal>

                        <SmartTable ref="prezzitesseredefault" url="/committee/membercard-price-default/index" :is_dialog="true"
                                    base_url="/committee/membercard-price-default" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziTessereDefault">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Tessere Default</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezziassicurazionidefault" url="/committee/insurance-price-default/index" :is_dialog="true"
                                    base_url="/committee/insurance-price-default" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziAssicurazioniDefault">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Assicurazioni Default</span></h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezzicertificatidefault" url="/committee/certificate-price-default/index" :is_dialog="true"
                                    base_url="/committee/certificate-price-default" base_path="/" :fixed_filters="[{ name: 'idcommittee', filter: $route.params.id }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziCertificatiDefault">
                            <template v-slot:title>
                                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi Certificati Default</span></h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab v-if="false" class="border border-primary border-top-0 rounded-bottom" title="Modifica" :title-link-class="linkClass(6)">
                        <SmartForm
                            base_url="/committee"
                            base_path="/committee"
                            :values_url="'/committee/edit?id=' + $route.params.id"
                            form_type="edit"
                        >
                            <template v-slot:title>
                                <h3 class="font-weight-bolder text-dark font-size-h3">Modifica Comitato</h3>
                            </template>

                            <template v-slot:toolbar>
                                <span></span>
                            </template>

                            <template v-slot:footer>
                                <div class="d-none"></div>
                            </template>
                        </SmartForm>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import SmartTable from "@/view/components/SmartTable.vue";
 import CommitteeChargeCredit from "@/view/components/CommitteeChargeCredit.vue";
 import SmartForm from "@/view/components/SmartForm.vue";
 import Swal from 'sweetalert2';
 import CurrencyInput from "@/view/components/CurrencyInput.vue";

 export default {
     data() {
         return {
             loading: false,
             loaded: false,
             tabIndex: 0,
             comitato: {},
             idcommitteemembercardrange: null,
             idsportingclub: null,
             sportingclubs: [],
             ranges: [],
             range: { min: 0, max: 0, value: [0, 0] },
             idmembercardtype: null,
             range_iniziale_comitato: 0,
             range_finale_comitato: 0,
             numero_tessere: 0,
             numero_tessere_sodalizio: 0,

             creditform: {
                 totalcredit: 0,
                 past_debt: 0,
                 note: '',
                 note_past_debt_internal: '',
                 note_past_debt_committee: '',
             },

             richiesteform: {
                 enablesportingclubrequestesflag: null,
                 sportingclubpricetype: '',
                 paymentrequestesinfo: '',
             },

             prezzoaffiliazioneform: {
                 enablecustomaffiliationpriceflag: null,
                 affiliationprice: 5,
             },

             operationform: {
                 operationammount: 0,
                 idoperationtype: 0,
                 description: '',
                 idpaymenttype: '',
                 note: '',
             },

             idannuality: '',
             annualities: [],

             membercard_range: '',
         };
     },

     created() {
         this.updateCommittee();
     },

     computed: {
         automaticassigncardflag() {
             let idannuality = this.idannuality;
             let found = this.annualities.find(function (item) {
                 return item.id === idannuality;
             });

             if (found)
             {
                 return found.automaticassigncardflag;
             }

             return false;
         },

         current_year() {
             let idannuality = this.idannuality;
             let found = this.annualities.find(function (item) {
                 return item.id === idannuality;
             });

             if (found)
             {
                 return found.label;
             }
             else
             {
                 if (this.annualities.length) {
                     return this.annualities[0].label;
                 }
             }

             return '';
         },

         totalcredit() {
             return this.formatEuro(this.comitato.credit.totalcredit);
         },

         past_debt() {
             const formatter = new Intl.NumberFormat('it-IT', {
                 style: 'currency',
                 currency: 'EUR'
             });

             let amount = formatter.format(this.comitato.credit.past_debt);

             return amount.replace('€', '');
         },

         quantita_tessere_scelte() {
             return parseInt(this.range.value[1]) - parseInt(this.range.value[0]) + 1;
         }
     },

     components: {
         SmartTable,
         SmartForm,
         CommitteeChargeCredit,
         CurrencyInput,
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Comitati", route: "/committee/gestione" },
             { title: "Gestione" }
         ]);

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length)
                {
                    let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idroletype');
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                }
                else return null;
            },
            (id) => {
                if (id) {
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idregion', id === 1201);
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate', id === 1201);
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idcity', id === 1201);
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetaddress', id === 1201);
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetnumber', id === 1201);
                    this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'zipcode', id === 1201);
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length)
                {
                    if (typeof this.$refs.consiglio === 'undefined') return false;
                    if (typeof this.$refs.consiglio.$refs.modalcomponent === 'undefined') return false;
                    if (typeof this.$refs.consiglio.$refs.modalcomponent.form_type === 'undefined') return false;

                    if (this.$refs.consiglio.$refs.modalcomponent.form_type === 'edit' || this.$refs.consiglio.$refs.modalcomponent.form_type === 'copy')
                    {
                        if (this.$refs.consiglio.componentValuesloaded === null || this.$refs.consiglio.componentValuesloaded === false) {
                            return false;
                        }
                    }

                    let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idregion');
                    let value = this.getFieldValue(field);

                    if (value && value.id) return value.id;

                    return null;
                }
                else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldProvstate = null;
                let fieldProvstateValue = null;

                if (newValue === false) {
                    doUpdate = false;
                }
                else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldProvstate = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate');
                        fieldProvstateValue = null;
                        if (fieldProvstate.value !== null && typeof fieldProvstate !== 'undefined' && fieldProvstate.value !== 'undefined') fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(this.$refs.consiglio.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                       function(options) {
                                           options.unshift({
                                               id: 0,
                                               provstatecode: 'Seleziona una provincia',
                                           });
                                       },
                                       function(options) {
                                           fieldProvstate = vm.findField(vm.$refs.consiglio.$refs.modalcomponent, 'idprovstate');

                                           if (fieldProvstate) {
                                               vm.$set(fieldProvstate, 'filter_options', options);

                                               if (typeof fieldProvstate.value !== "undefined") {
                                                   if (fieldProvstateValue !== null && typeof fieldProvstateValue !== 'undefined') {
                                                       fieldProvstate.value = JSON.parse(JSON.stringify(fieldProvstateValue));
                                                   }
                                                   else {
                                                       fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                   }
                                               }
                                           }
                                       }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length)
                {
                    if (typeof this.$refs.consiglio === 'undefined') return false;
                    if (typeof this.$refs.consiglio.$refs.modalcomponent === 'undefined') return false;
                    if (typeof this.$refs.consiglio.$refs.modalcomponent.form_type === 'undefined') return false;

                    if (this.$refs.consiglio.$refs.modalcomponent.form_type === 'edit' || this.$refs.consiglio.$refs.modalcomponent.form_type === 'copy')
                    {
                        if (this.$refs.consiglio.componentValuesloaded === null || this.$refs.consiglio.componentValuesloaded === false) {
                            return false;
                        }
                    }

                    let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate');
                    let value = this.getFieldValue(field);

                    if (typeof value !== "undefined" && value && value.id) return value.id;

                    return null;
                }
                else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldCity = null;
                let fieldCityValue = null;

                if (newValue === false) {
                    doUpdate = false;
                }
                else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldCity = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idcity');
                        fieldCityValue = null;
                        if (fieldCity.value !== null && typeof fieldCity !== 'undefined' && fieldCity.value !== 'undefined') fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(this.$refs.consiglio.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                       function(options) {
                                           options.unshift({
                                               id: 0,
                                               cityname: 'Seleziona una città',
                                           });
                                       },
                                       function (options) {
                                           fieldCity = vm.findField(vm.$refs.consiglio.$refs.modalcomponent, 'idcity');

                                           if (fieldCity) {
                                               vm.$set(fieldCity, 'filter_options', options);

                                               if (typeof fieldCity.value !== "undefined") {
                                                   if (fieldCityValue !== null && typeof fieldCityValue !== 'undefined') {
                                                       fieldCity.value = JSON.parse(JSON.stringify(fieldCityValue));
                                                   }
                                                   else {
                                                       fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                   }
                                               }
                                           }
                                       }
                        );
                    }
                }
            }
        );
     },

     watch: {
         idannuality(value) {
             let vm = this;
             setTimeout(function(){
                 vm.$refs.tesseredisponibili.refresh();
             }, 100);
         },

        membercard_range(value) {
            let vm = this;
            setTimeout(function(){
                vm.$refs.tesseredisponibili.refresh();
            }, 100);
        }
     },

     methods: {
         clearMembercardRange() {
             this.membercard_range = '';
         },

         updateCommittee() {
             let url = '/committee/manage/' + this.$route.params.id;
             ApiService.query(url)
                       .then((response) => {
                           this.comitato = response.data.comitato;

                           this.richiesteform.enablesportingclubrequestesflag = JSON.parse(JSON.stringify(this.comitato.credit.enablesportingclubrequestesflag));
                           this.richiesteform.sportingclubpricetype = JSON.parse(JSON.stringify(this.comitato.credit.sportingclubpricetype));
                           if (!this.richiesteform.sportingclubpricetype) this.richiesteform.sportingclubpricetype = '';
                           this.richiesteform.paymentrequestesinfo = JSON.parse(JSON.stringify(this.comitato.credit.paymentrequestesinfo));

                           this.prezzoaffiliazioneform.enablecustomaffiliationpriceflag = JSON.parse(JSON.stringify(this.comitato.credit.enablecustomaffiliationpriceflag));
                           this.prezzoaffiliazioneform.affiliationprice = JSON.parse(JSON.stringify(this.comitato.credit.affiliationprice));

                           this.idannuality = response.data.idannuality;
                           this.annualities = response.data.annualities;

                           this.loaded = true;
                       })
                       .catch((error) => {
                           console.log(error);
                       })
         },

         updateCredit() {
             this.updateCommittee();
             this.$refs.creditoperations.refresh();
         },

         formatEuro(value) {
             const formatter = new Intl.NumberFormat('it-IT', {
                 style: 'currency',
                 currency: 'EUR'
             });

             let amount = formatter.format(value);

             return amount.replace('€', '');
         },

         linkClass(idx) {
             if (this.tabIndex === idx) {
                 return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
             } else {
                 return ['bg-primary', 'text-white', 'font-size-h5']
             }
         },

         checkrange() {
             if (this.range.value[0] < this.range.min) this.$set(this.range.value, 0, this.range.min);
             if (this.range.value[1] > this.range.max) this.$set(this.range.value, 1, this.range.max);
         },

         checkoperationammount() {
             if (this.operationform.operationammount < 0) this.operationform.idpaymenttype = 3104;
         },

         checkrangeComitato() {
             if (this.range_iniziale_comitato < 0) this.range_iniziale_comitato = 0;
             if (this.range_finale_comitato < 0) this.range_finale_comitato = 0;
             if (this.numero_tessere < 0) this.numero_tessere = 0;
         },

         checkrangeSodalizio() {
             if (this.numero_tessere_sodalizio < 0) this.numero_tessere_sodalizio = 0;
         },

         closeModalPrezziTessere() {
             this.$refs.prezzitessere.current_page = 1;
             this.$refs.prezzitessere.getItems();
         },

         closeModalPrezziAssicurazioni() {
             this.$refs.prezziassicurazioni.current_page = 1;
             this.$refs.prezziassicurazioni.getItems();
         },

         closeModalPrezziCertificati() {
             this.$refs.prezzicertificati.current_page = 1;
             this.$refs.prezzicertificati.getItems();
         },

         closeModalPrezziTessereDefault() {
             this.$refs.prezzitesseredefault.current_page = 1;
             this.$refs.prezzitesseredefault.getItems();
         },

         closeModalPrezziAssicurazioniDefault() {
             this.$refs.prezziassicurazionidefault.current_page = 1;
             this.$refs.prezziassicurazionidefault.getItems();
         },

         closeModalPrezziCertificatiDefault() {
             this.$refs.prezzicertificatidefault.current_page = 1;
             this.$refs.prezzicertificatidefault.getItems();
         },

         assignCards(id) {
             this.loading = true;

             this.idcommitteemembercardrange = id;
             this.ranges = [];
             this.range = { min: 0, max: 0, value: [0, 0] };

             let url = '/committee/load-options?type=assigncards&idcommitteemembercardrange=' + id + '&idannuality=' + this.idannuality;
             ApiService.query(url)
                       .then((response) => {
                           this.sportingclubs = response.data.sportingclubs;
                           this.ranges = response.data.ranges;

                           let vm = this;
                           setTimeout(function(){
                               vm.$refs['assegnaTessereModal'].show();
                           }, 100);

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         deleteCardsCommittee(id) {
             this.idcommitteemembercardrange = id;

             Swal.fire({
                 title: 'Conferma eliminazione',
                 text: "Sei sicuro di voler eliminare lo stock di tessere?",
                 icon: 'error',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/committee/delete-cards-committee';

                     ApiService.post(url, { idcommitteemembercardrange: this.idcommitteemembercardrange })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Stock Tessere eliminato correttamente', {
                                   title: 'Stock Tessere eliminato correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               this.$refs.tesseredisponibili.refresh();

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               if (response.data.message === 'exists')
                               {
                                   this.$bvToast.toast('Lo Stock di tessere non può essere eliminato: alcune tessere sono state già assegnate.', {
                                       title: 'Lo Stock di tessere non può essere eliminato.',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                               else
                               {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione dello stock delle tessere', {
                                       title: 'Errore nell\'eliminazione dello stock delle tessere',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione dello stock delle tessere', {
                               title: 'Errore nell\'eliminazione dello stock delle tessere',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       })
                 }
             })
         },

         deleteCards(id) {
             Swal.fire({
                 title: 'Conferma eliminazione',
                 text: "Sei sicuro di voler eliminare lo stock di tessere?",
                 icon: 'error',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/committee/delete-cards';

                     ApiService.post(url, { idsportingclubmembercardrange: id })
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Stock Tessere eliminato correttamente', {
                                           title: 'Stock Tessere eliminato correttamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       let vm = this;
                                       setTimeout(function(){
                                           vm.$refs['tessereAssegnateTable'].getOptions();
                                           vm.$refs['tessereAssegnateTable'].refresh();
                                           vm.$refs.tesseredisponibili.refresh();
                                       }, 100);

                                       this.loading = false;
                                   }

                                   if (response.data.status === 'KO') {
                                       if (response.data.message === 'exists')
                                       {
                                           this.$bvToast.toast('Lo Stock di tessere non può essere eliminato: alcune tessere sono state già assegnate.', {
                                               title: 'Lo Stock di tessere non può essere eliminato.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                       else
                                       {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione dello stock delle tessere', {
                                               title: 'Errore nell\'eliminazione dello stock delle tessere',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   }

                                   this.loading = false;
                               })
                               .catch((error) => {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione dello stock delle tessere', {
                                       title: 'Errore nell\'eliminazione dello stock delle tessere',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   console.log(error);

                                   this.loading = false;
                               });
                 }
             })
         },

         modificaCreditoComitato() {
             this.loading = true;

             let url = '/committee/load-options?type=editcommitteecredit&idcommittee=' + this.$route.params.id;
             ApiService.query(url)
                       .then((response) => {
                           this.creditform = response.data.creditform;

                           let vm = this;
                           setTimeout(function(){
                               vm.$refs['modificaCreditoComitatoModal'].show();
                           }, 100);

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         confermaAggiungiOperazioneComitato() {
             this.loading = true;

             let url = '/committee/add-credit-operation';

             ApiService.post(url, { idcommittee: this.$route.params.id, operationform: this.operationform })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Operazione Credito Comitato Aggiunta Correttamente', {
                                   title: 'Operazione Credito Comitato Aggiunta Correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['aggiungiOperazioneComitatoModal'].hide();
                               }, 100);

                               this.operationform.operationammount = 0;
                               this.operationform.idoperationtype = 0;
                               this.operationform.description = '';
                               this.operationform.idpaymenttype = '';
                               this.operationform.note = '';

                               this.loading = false;

                               this.updateCredit();
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta dell\'operazione al credito del Comitato', {
                                   title: 'Errore nell\'aggiunta dell\'operazione al credito del Comitato',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta dell\'operazione al credito del Comitato', {
                               title: 'Errore nell\'aggiunta dell\'operazione al credito del Comitato',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       });
         },

         mostraModaleTessereComitato() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['assegnaTessereComitatoModal'].show();
             }, 200);
         },

         aggiungiOperazioneComitato() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['aggiungiOperazioneComitatoModal'].show();
             }, 200);
         },

         confermaAssegnaTessere() {
             this.loading = true;

             let url = '/committee/assign-cards';

             ApiService.post(url, { sportingclub: this.idsportingclub, idcommitteemembercardrange: this.idcommitteemembercardrange, range: this.range, cardamount: this.numero_tessere_sodalizio, idannuality: this.idannuality })
                 .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Tessere assegnate correttamente', {
                                   title: 'Tessere assegnate correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['assegnaTessereModal'].hide();
                               }, 100);

                               this.loading = false;

                               this.$refs['tesseredisponibili'].refresh();
                           }

                           if (response.data.status === 'KO') {
                               if (response.data.message === 'exists')
                               {
                                   this.$bvToast.toast('Il range di tessere è già stato assegnato.', {
                                       title: 'Il range di tessere è già stato assegnato.',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                               else
                               {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'assegnazione delle tessere', {
                                       title: 'Errore nell\'assegnazione delle tessere',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nell\'assegnazione delle tessere', {
                               title: 'Errore nell\'assegnazione delle tessere',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       });
         },

         confermaAssegnaTessereComitato() {
             this.loading = true;

             let url = '/committee/assign-cards-committee';

             ApiService.post(url, { idcommittee: this.$route.params.id, idmembercardtype: this.idmembercardtype, cardnumberstart: this.range_iniziale_comitato, cardnumberend: this.range_finale_comitato, cardamount: this.numero_tessere, idannuality: this.idannuality })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Tessere assegnate correttamente al Comitato', {
                                   title: 'Tessere assegnate correttamente al Comitato',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['assegnaTessereComitatoModal'].hide();
                               }, 100);

                               this.loading = false;

                               this.$refs['tesseredisponibili'].refresh();
                           }

                           if (response.data.status === 'KO') {
                               if (response.data.message === 'exists')
                               {
                                   this.$bvToast.toast('Il range di tessere è già stato assegnato.', {
                                       title: 'Il range di tessere è già stato assegnato.',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                               else
                               {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'assegnazione delle tessere al Comitato', {
                                       title: 'Errore nell\'assegnazione delle tessere al Comitato',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nell\'assegnazione delle tessere al Comitato', {
                               title: 'Errore nell\'assegnazione delle tessere al Comitato',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       })
         },

         hideModalAssegnaTessere() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['assegnaTessereModal'].hide();
             }, 200);
         },

         hideModalAssegnaTessereComitato() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['assegnaTessereComitatoModal'].hide();
             }, 200);
         },

         hideModalModificaCreditoComitato() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['modificaCreditoComitatoModal'].hide();
             }, 200);
         },

         hideModalAggiungiOperazioneComitato() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['aggiungiOperazioneComitatoModal'].hide();
             }, 200);
         },

         viewCards(id) {
             this.idcommitteemembercardrange = id;

             let vm = this;
             setTimeout(function(){
                 vm.$refs['tessereAssegnateTable'].getOptions();
                 vm.$refs['tessereAssegnateTable'].refresh();
             }, 100);
         },

         confermaModificaCreditoComitato() {
             this.loading = true;

             let url = '/committee/edit-committee-credit';

             ApiService.post(url, { idcommittee: this.$route.params.id, creditform: this.creditform })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Credito Comitato Modificato Correttamente', {
                                   title: 'Credito Comitato Modificato Correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['modificaCreditoComitatoModal'].hide();
                               }, 100);

                               this.creditform.totalcredit = 0;
                               this.creditform.past_debt = 0;
                               this.creditform.note = '';
                               this.creditform.note_past_debt_internal = '';
                               this.creditform.note_past_debt_committee = '';

                               this.loading = false;

                               this.updateCredit();
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nella modifica del Credito del Comitato', {
                                   title: 'Errore nella modifica del Credito del Comitato',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nella modifica del Credito del Comitato', {
                               title: 'Errore nella modifica del Credito del Comitato',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       })
         },

         CreditRecharged() {
             this.updateCredit();
         },

         modificaInfoRichieste() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['modificaInfoRichiesteModal'].show();
             }, 200);
         },

         modificaPrezzoAffiliazione() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['modificaPrezzoAffiliazioneModal'].show();
             }, 200);
         },

         hideModificaInfoRichiesteModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['modificaInfoRichiesteModal'].hide();
             }, 200);
         },

         hideModificaPrezzoAffiliazioneModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['modificaPrezzoAffiliazioneModal'].hide();
             }, 200);
         },

         confermaModificaInfoRichiesteModal() {
             this.loading = true;

             let url = '/committee/edit-payment-info';

             ApiService.post(url, { idcommittee: this.$route.params.id, richiesteform: this.richiesteform })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Stato Richieste Modificato Correttamente', {
                                   title: 'Stato Richieste Modificato Correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['modificaInfoRichiesteModal'].hide();
                               }, 100);

                               this.comitato.credit.enablesportingclubrequestesflag = JSON.parse(JSON.stringify(this.richiesteform.enablesportingclubrequestesflag));
                               this.comitato.credit.sportingclubpricetype = JSON.parse(JSON.stringify(this.richiesteform.sportingclubpricetype));
                               this.comitato.credit.paymentrequestesinfo = JSON.parse(JSON.stringify(this.richiesteform.paymentrequestesinfo));

                                   this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nella modifica dello stato delle richieste', {
                                   title: 'Errore nella modifica dello stato delle richieste',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nella modifica dello stato delle richieste', {
                               title: 'Errore nella modifica dello stato delle richieste',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       })
         },

         confermaModificaPrezzoAffiliazioneModal() {
             this.loading = true;

             let url = '/committee/edit-affiliation-price';

             ApiService.post(url, { idcommittee: this.$route.params.id, prezzoaffiliazioneform: this.prezzoaffiliazioneform })
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('Prezzo Affiliazione Modificato Correttamente', {
                                   title: 'Prezzo Affiliazione Modificato Correttamente',
                                   variant: 'success',
                                   solid: true
                               });

                               let vm = this;
                               setTimeout(function(){
                                   vm.$refs['modificaPrezzoAffiliazioneModal'].hide();
                               }, 100);

                               this.comitato.credit.enablecustomaffiliationpriceflag = JSON.parse(JSON.stringify(this.prezzoaffiliazioneform.enablecustomaffiliationpriceflag));
                               this.comitato.credit.affiliationprice = JSON.parse(JSON.stringify(this.prezzoaffiliazioneform.affiliationprice));

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nella modifica del prezzo dell\'affiliatione', {
                                   title: 'Errore nella modifica del prezzo dell\'affiliazione',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           this.$bvToast.toast('Si sono verificati degli errori nella modifica del prezzo dell\'affiliazione', {
                               title: 'Errore nella modifica del prezzo dell\'affiliazione',
                               variant: 'danger',
                               solid: true
                           });

                           console.log(error);

                           this.loading = false;
                       })
         },

         closeModalOrganigramma() {
             let vm = this;
             setTimeout(function(){
                vm.$refs.organigramma.refresh();
                vm.$refs.storicoorganigramma.refresh();
             }, 100);
         },

         closeModalConsiglio() {
             let vm = this;
             setTimeout(function(){
                vm.$refs.consiglio.refresh();
                vm.$refs.storicoorganigramma.refresh();
             }, 100);
         },

        findField(obj, name) {
            if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                return obj.fields.find(item => item.name === name);
            }

            return null;
        },

        getFieldValue(field) {
            if (field) {
                let value = typeof field.value !== "undefined" ? field.value : null;

                if (value !== null) {
                    return (JSON.parse(JSON.stringify(value)));
                }
            }

            return null;
        },

        setFieldVisible(obj, name, visible) {
            if (typeof obj.fields !== "undefined") {
                let fieldIndex = obj.fields.findIndex(item => item.name === name);
                if (fieldIndex) {
                    if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                    if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                }
            }
        },

        setFieldAttribute(obj, name, attribute, value) {
            let fieldIndex = obj.fields.findIndex(item => item.name === name);
            if (fieldIndex) {
                this.$set(obj.fields[fieldIndex], attribute, value);
            }
        },

        loadOptions(obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
            let url = '/sportingclub/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

            ApiService.query(url)
                      .then((response) => {
                          let options = response.data;

                          if (typeof options === "undefined") options = [];

                          callbackEmptyOption(options);

                          if (callbackOptions !== null) {
                              callbackOptions(options);
                          }
                          else {
                              this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                          }
                      })
                      .catch((error) => {
                          console.log(error);

                          let options = [];

                          callbackEmptyOption(options);

                          if (callbackOptions !== null) {
                              callbackOptions(options);
                          }
                          else {
                              this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                          }
                      })
        },

        getApiUrl() {
            return ApiService.getApiUrl();
        },

        closeModal() {
            this.$refs.creditoperations.refresh();
        },
     }
 };
</script>

<style>
 .border-bluenavy .card-header {
	 border-bottom: 1px solid #143D67;
 }

 #app {
     height: 200px !important;
 }

 .v-application--wrap {
     height: 200px !important;
     min-height: auto !important;
 }
</style>
